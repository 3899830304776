.container {
  margin-bottom: 29px;
}

.title {
  font-size: 36px !important;
  line-height: 42.19px !important;
}

.subtitle {
  font-size: 22px !important;
  line-height: 25.78px !important;
}

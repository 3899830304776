.iconBack {
  display: block;
  margin-bottom: 15px;

  svg {
    width: 55px;
    height: 55px;
  }

  &:hover {
    cursor: pointer;
  }
}

.container {
  background-color: #000;
  border-radius: 10px;
  padding: 37px 48px;
  color: #FFEA20;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 30px 15px;
  }
}

.headingContainer {
  margin-bottom: 18px;
  text-align: center;
}

.heading {
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.subtitleContainer {
  margin-bottom: 38px;
  text-align: center;
}

.subtitle {
  font-size: 22px;
  line-height: 28.6px;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.leftContainer {
  width: 55%;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }
}

.rightContainer {
  width: 45%;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }
}

.stepButtonContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.stepNextButton {
  border-radius: 10px;
  background-color: #fff;
  padding: 17px 20px;
  text-align: center;
  width: 100%;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.stepPrevButton {
  text-align: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

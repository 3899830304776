.header {
    font-size: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
        width: 40px;
    }
}

.container {
    width: 100vh;
    max-width: 512px;
    margin: 0 auto;
    padding: 0 15px;
}

.box {
    margin: 20px 0;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    padding: 30px 22px;
    text-align: center;
    width: 100%;

    p {
        margin: 12px 0;
    }
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px;
    width: 100%;

    a,
    button {
        font-size: 16px;
        padding: 20px 12px;
        width: 100%;
    }
}

.button {
    background-color: #000;
    color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }
}

.topHeader {
    margin-bottom: 44px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin-bottom: 20px;
    }
}

.topTab {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        flex-direction: column;
    }
}

.tabButton {
    padding: 10px;
    border-radius: 10px;
    background: #fffdd0;
    color: #000;
    border: none;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }
}

.tabButtonActive {
    background: #4169e1;
    color: #fff;
}

.topRow {
    margin-bottom: 75px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin-bottom: 16px;
    }
}

.tableRow {
    margin-bottom: 51px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin-bottom: 16px;
    }
}

.simpleContainer {
    background: #000;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    justify-content: center;
    padding: 50px 80px;
    margin-bottom: 100px;

    h2 {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 60px;

        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 60px;

        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    a {
        width: fit-content;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding: 50px 20px;
    }
}

.demoButton {
    border-radius: 0;
    width: 250px !important;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

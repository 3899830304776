.header {
    font-size: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
        width: 40px;
    }
}

.subHeader {
    font-weight: 500;
    text-align: center;
    margin: 20px 0;
}

.container {
    width: 100vh;
    max-width: 512px;
    margin: 0 auto;
    padding: 0 15px;
}

.box {
    margin-top: 20px;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    padding: 30px 22px;
    text-align: center;
    width: 100%;

    p {
        margin: 12px 0;
    }
    img {
        width: 100px;
    }
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px;
    width: 100%;

    a,
    button {
        font-size: 16px;
        padding: 20px 12px;
        width: 100%;
    }
}

.button {
    background-color: #000;
    color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    font-weight: bold;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
}

.miniText {
    margin-top: 30px;
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 20px;
}

.steps {
    text-align: left;
    margin: 10px 0;
    padding: 0 20px;

    li {
        font-size: 17px;
        margin: 10px 0;
        span {
            font-weight: 600;
        }
    }
}

.iosIcons {
    margin-left: 5px;
    margin-right: 5px;

    img {
        margin-left: 2px;
    }
}

.alert {
    margin-top: 25px;
    font-weight: bold;
    color: red;
}
.footerText {
    margin: 20px 0;
    text-align: center;
    font-weight: 500;
}

.icon {
    width: 30px;
    height: 30px;
    margin-bottom: -6px;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.container {
  margin-bottom: 45px;
}

.card {
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  color: #FFEA20;
  background-color: lighten(#000, 10%);
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: #FFEA20;
    color: #000;
  }

  p {
    font-size: 24px;
    line-height: 28.13px;
    font-weight: bold;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &:hover {
    &:before {
      opacity: 0.2;
    }
  }
}

.iconContainer {
  margin-left: auto;
}

.cardContent {
  background: #fff;
  padding: 33px;
  color: #000;
  width: 95%;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 10px;
    width: 100%;
  }
}

.counterContainer {
  margin-top: 49px;
  display: flex;
  font-weight: bold;
  color: #000;
  align-items: center;

  p {
    width: 75%;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      width: 100%;
    }
  }
}

.counter {
  width: 25%;
  display: flex;
  gap: 20px;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: auto;
  }
}

.counterIcon {
  &:hover {
    cursor: pointer;
  }
}

.addToCartLabel {
  margin-top: 15px;
  font-weight: bold;
  padding: 10px;
  background-color: #000;
  color: #FFEA20;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-right: 15px;
  }
}
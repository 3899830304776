$color-yellow: #FFD700;

.title {
  font-size: 22px;
  line-height: 25.78px;
  text-transform: none;
}

.yellow {
  color: $color-yellow !important;
}
.footerContainer {
  background-color: #000;
  display: flex;
  align-content: center;
  padding: 24px;
  border-radius: 10px;
  color: #fff;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: block;
  }
}

.footerTitle1 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 26.63px;
  font-weight: bold;
}

.footerIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  &:hover {
    cursor: pointer;
  }
}

.footerLeft {
  align-items: center;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin-bottom: 16px;
  }
}

.footerRight {
  margin-left: auto;
}

.logoutButton {
  padding: 12px 38px;
  background-color: #5c5c5c;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  width: 214px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  // @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  //   width: 50%;
  // }
}

.supportButton {
  padding: 12px 38px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  width: 214px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  // @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  //   width: 50%;
  // }
}

.footerSupportIcon {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  object-fit: contain;
}

.daySelector {
  text-align: center;

  p {
    font-weight: bold;
  }
}

.selectorContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 25px;
  max-width: 100%;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.selectBox {
  background-color: darken(#FFEA20, 20%);
  color: #000;
  border-radius: 10px;
  padding: 24px 28px;
  font-weight: bold;
  -webkit-transition: .2s background-color ease-in-out;
  transition: .2s background-color ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #FFEA20;
  }
}

.activeBox {
  background-color: #FFEA20;
}

.timeSelector {
  text-align: center;

  p {
    font-weight: bold;
  }
}

.selectorContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 25px;
}

.selectBox {
  background-color: darken(#FFEA20, 20%);
  color: #000;
  border-radius: 10px;
  padding: 24px 28px;
  font-weight: bold;
  -webkit-transition: .2s background-color ease-in-out;
  transition: .2s background-color ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #FFEA20;
  }
}

.activeBox {
  background-color: #FFEA20;
}

.notesContainer {
  margin-top: 60px;

  p {
    margin-bottom: 15px;
  }
}

.textField {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  line-height: 23px;
}
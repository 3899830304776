$color-black: #000;
$color-yellow: #ffd700;

.container {
    padding: 20px 26px;
    position: fixed;
    top: 0;
    background-color: #000;
    z-index: 100;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        display: flex;
        flex-direction: column !important;
    }
}

.spaceContainer {
    height: 80px;
    width: 100%;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        height: 150px;
    }
}

.iconText {
    font-size: 22px !important;
    line-height: 25.78px !important;
    font-weight: 700 !important;
    color: #fff;

    &:hover {
        cursor: pointer;
    }
}

.button {
    float: right;
    width: 200px;
    background-color: $color-yellow;
    color: $color-black;
    padding: 12px 16px;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-decoration: none !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold !important;
    font-family: "Roboto", sans-serif;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding: 12px;
        flex: 1;
    }
}

.buttonSupport {
    width: auto;
}

.buttonIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-left: 8px;
}

.topButtonContainer {
    margin-left: auto !important;
    max-width: 100% !important;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        width: 100% !important;
        margin-left: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

// .topButtonStack {
//     // @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
//     //     width: 158px;
//     // }
// }

.topButtonSingle {
    display: block !important;
}

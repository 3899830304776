@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.iconBack {
  display: block;
  margin-bottom: 15px;

  svg {
    width: 55px;
    height: 55px;
  }

  &:hover {
    cursor: pointer;
  }
}

.container {
  background-color: #000;
  border-radius: 10px;
  padding: 37px 48px;
  color: #FFEA20;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 10px 15px;
  }
}

.headingContainer {
  margin-bottom: 18px;
  text-align: center;
}

.heading {
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.subtitleContainer {
  margin-bottom: 38px;
  text-align: center;
}

.subtitle {
  font-size: 22px;
  line-height: 28.6px;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.cardContainer {
  margin-bottom: 62px;
  display: flex;
  justify-content: center;
}

.card {
  padding: 18px 27px;
  background-color: #A1A1A1;
  color: #fff;
  border-radius: 10px;
  display: flex;
  width: 381px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  p {
    font-size: 24px;
    line-height: 28.13px;
    font-weight: bold;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.cardButtonIcon {
  width: 49px;
  height: 49px;
  object-fit: contain;
}

.activeCard {
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  color: #FFEA20;
  background-color: lighten(#000, 10%);
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #FFEA20;
    color: #000;
  }
}

.footerIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  &:hover {
    cursor: pointer;
  }
}

.card {
  height: 239px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBody {
  text-align: center;
}

.cardHeading {
  font-size: 50px;
  line-height: 70px;
}

.cardSubtitle {
  font-size: 16px;
  line-height: 30px;
}
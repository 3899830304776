$color-bg: #f3cb27;
$color-black: #000;
$color-yellow: #ffd700;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,700&display=swap");

body {
    background-color: $color-bg;
    font-family: "Roboto", sans-serif;
    color: $color-black;
    padding-top: 60px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding-top: 30px;
    }
}

button {
    font-family: "Roboto", sans-serif;
}

.font-custom {
    font-family: "Roboto", sans-serif;
}

.theme-button {
    background-color: $color-black;
    color: $color-yellow !important;
    text-decoration: none;
    padding: 12px 40px;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: bold;
    font-size: 16px;
    border-radius: 10px;
    line-height: 19.36px;

    &:hover {
        text-decoration: none;
    }
}

.theme-button-inverse {
    color: $color-black !important;
    background-color: $color-yellow !important;
    text-decoration: none;
    padding: 12px 40px;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: bold;
    font-size: 16px;
    border-radius: 10px;
    line-height: 19.36px;

    &:hover {
        text-decoration: none;
    }
}

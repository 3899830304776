.container {
  background-color: #000;
  border-radius: 10px;
  padding: 37px 48px;
  color: #FFEA20;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 30px 15px;
  }
}

.cartList {
  margin-top: 22px;
  list-style-type: none;

  li {
    font-size: 18px;
    line-height: 23.4px;
    display: flex;
    margin-bottom: 15px;

    & > div {
      display: flex;
    }

    span {
      margin-left: auto;
    }
  }
}

.cartRemoveIcon {
  display: inline-block;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
  }
}

.discountContainer {
  margin-top: 35px;
  
  h3 {
    color: #70FF00;
  }

  ul {
    list-style-type: none;

    li {
      display: flex;
      margin-bottom: 15px;

      span {
        margin-left: auto;
      }
    }
  }
}

.grandTotalContainer {
  margin-top: 40px;
  border-top: thin solid #fff;
  padding-top: 20px;
  font-weight: bold;
  display: flex;

  span {
    margin-left: auto;
  }
}
